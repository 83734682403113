<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- /.card-header -->
                    <!-- form start -->
            <form role="form" @submit.prevent="submitForm">
                <div class="invoice p-3 mb-3">
                    <div class="row">
                        <div class="card card-default">
                            <!-- <form role="form" @submit="submitForm"> -->
                            <div class="card-header">
                                <h3 class="card-title"></h3>

                                <div class="card-tools">
                                    <button
                                    type="button"
                                    class="btn btn-tool"
                                    data-card-widget="collapse"
                                    >
                                    <i class="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body">
          <div class="row">
						<div class="col-md-6 form-group">
                <label class="control-label">Kategori</label>
                <select v-model="form.kategori_id" class="form-control">
                    <option v-for="item in kategori" v-bind:value="item.id" :key="item.id">{{ item.label }}</option>
                </select>
            </div>
								<div class="col-md-12 form-group">
										<label class="control-label">Cabang</label>
										<select
												v-model="form.branch_id"
												id="branch_id"
												name="branch_id"
												class="form-control"
										>
												<option
												v-for="cabang in groups"
												v-bind:key="cabang.branch_id"
												v-bind:value="cabang.branch_id"
												>
												{{ cabang.name }}
							</option>
                      </select>
                  </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Judul</label>
                                <input id="title" class="form-control" v-model="form.title" type="text" name="title" />
                            </div>
														<div class="col-md-12 form-group">
                                <label class="control-label">Tanggal Terbit</label>
                                <datepicker v-model="form.published_on" />
                            </div>
														<div class="col-md-12 form-group">
                                <label class="control-label">Diterbitkan Sampai</label>
                                <datepicker v-model="form.published_until" />
                            </div>
														<div class="col-md-12 form-group">
															<div class="col-5">
																<label for="active" class="control-label">Status</label>
															</div>
															<div class="form-check form-check-inline ml-2">
																<input
																		class="form-check-input"
																		type="radio"
																		name="active"
																		id="active"
																		v-model="form.active"
																		value="0"
																/>
																<label class="form-check-label" for="active">DRAFT</label>
															</div>
															<div class="form-check form-check-inline">
																<input
																		class="form-check-input"
																		type="radio"
																		name="active"
																		id="active"
																		v-model="form.active"
																		value="1"
																/>
																<label class="form-check-label" for="active"
																		>ACTIVE</label>
															</div>
															<div class="form-check form-check-inline">
																<input
																		class="form-check-input"
																		type="radio"
																		name="active"
																		id="active"
																		v-model="form.active"
																		value="2"
																/>
																<label class="form-check-label" for="active"
																		>NON ACTIVE</label>
															</div>
														</div>
														<div class="col-md-6 form-group">
                                <label for="inputFile">Foto</label>
																<input type="hidden" name="img_cover" value="" v-model="form.img_cover" id="img_cover" />
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFile">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                            <label class="control-label">Isi Artikel</label>
                            <vue-editor v-model="form.konten" :editor-toolbar="customToolbar" />
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-primary mr-1">Simpan</button>
                    <router-link
                    to="/artikel"
                    class="btn btn-secondary"
                    >
                    Kembali
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';
import moment from 'moment';

export default {
    data() {
        return {
            errors: [],
            groups: [],
						kategori: [
                {id: 0, label: 'INFORMASI/PENGUMUMAN'},
                {id: 1, label: 'BERITA'},
                {id: 2, label: 'PROMOSI'},
            ],
            roles: "",
            method: "POST",
            form: {
                title: "",
                konten: "<h1>Ketik artikel di sini..</h1>",
                branch_id: "",
                kategori_id: "",
                published_on: "",
                published_until: "",
                active: "",
                img_cover: "",
            },
						// form_data: "",
            customToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ header: 1 }, { header: 2 }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link", "image", "video"],
                [{ direction: "rtl" }],
            ],
        };
    },
    components: { 
        VueEditor,
        vSelect,
				datepicker,
    },
    created: function() {
        // alert(this.$route.params.id);
        if (this.$route.params.id) {
            this.loadArtikel(this.$route.params.id);
            this.method = "PUT";
        }

        authFetch("/akademik/data_induk_ruangan/groups")
					.then((res) => {
						if (res.status === 201) {
						} else if (res.status === 400) {
						}
						return res.json();
					})
					.then((js) => {
						this.groups = js.data;
				});
    },
    methods: {
        loadArtikel(id) {
            authFetch("/akademik/artikel/edit_artikel/" + id).then((res) => {
                res.json().then((json) => {
                    if (json) {
                        this.form = json;
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                    }
                });
            });
        },
				submitForm: function(ev) {
            const e = this.$refs;
						var self = this;

						var file_data = $('#inputFile').prop('files');
						if (file_data.length == 0) {
							console.log("upload kosong");
      	      var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
      	      var urlSubmit = '/akademik/artikel';
      	      if (this.method == 'PUT') urlSubmit = '/akademik/artikel/' + this.form.id;

							Swal.fire({
									title: "Simpan?",
									showCancelButton: true,
									confirmButtonText: `Ya`,
									cancelButtonText: "Tidak",
							}).then((result) => {
									if (result.isConfirmed) {
									authFetch(urlSubmit, {
											method: this.method,
											body: data,
									})
											.then((res) => {
											return res.json();
											})
											.then((js) => {
											if (js.success) {
													Swal.fire("Proses Berhasil", ``, "success");
													this.form = {};
													this.$router.push('/artikel');
											} else {
													Swal.fire("Proses gagal", ``, "error");
													}
											});
									}
							});
						} else {
							this.doUpload().then((res) => {
                if (res.status === 201) {

                } else if (res.status === 400) {}

                return res.json();
                
            }).then(js => {
                console.log('result')
                console.log(js)
                this.errors = [];
                if (!js.status) {
                    console.log(js.details)

                    for (var key in js.details) {
                        if (js.details.hasOwnProperty(key)) {
                            this.errors.push(js.details[key])
                        }
                    }

                    return;
                } else {
                    var self = this;
                    const e = this.$refs;
                    if (js.message[0]) {
                        self.form.img_cover = js.message[0].code;
                    }

                    var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                    var urlSubmit = '/akademik/artikel';
                    if (this.method == 'PUT') urlSubmit = '/akademik/artikel/' + this.form.id;

										Swal.fire({
												title: "Simpan?",
												showCancelButton: true,
												confirmButtonText: `Ya`,
												cancelButtonText: "Tidak",
										}).then((result) => {
												if (result.isConfirmed) {
												authFetch(urlSubmit, {
														method: this.method,
														body: data,
												})
														.then((res) => {
														return res.json();
														})
														.then((js) => {
														if (js.success) {
																Swal.fire("Proses Berhasil", ``, "success");
																this.form = {};
																this.$router.push('/artikel');
														} else {
																Swal.fire("Proses gagal", ``, "error");
																}
														});
												}
										});
          }
            });
						}
            ev.preventDefault();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/artikel/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/akademik/artikel/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        }
    },
    mounted() {
        const e = this.$refs;
        const self = this;
    },
}
</script>